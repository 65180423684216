import React, { useRef } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '@fortawesome/fontawesome-free/css/all.css';
import { faPhone , faMobile , faEnvelope , faLocationDot } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import './Contactus.css';

const BASE_URL=process.env.REACT_APP_BACKEND_URL;

const Contactus = () => {
  const formRef = useRef(null);

  const sendEmail = async (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);

    try {
      const response = await axios.post(`${BASE_URL}/send-email`, Object.fromEntries(formData));
      if (response.status === 200) {
        console.log("sucessfull")
        toast.success('Email sent successfully!');
        window.alert("Sent Successfully");
      } else {
        throw new Error('Failed to send email');
        window.alert("Error while processing");
      }
    } catch (error) {
      console.error('Error sending email:', error);
      window.alert("Error while processing");
      toast.error('Failed to send email. Please try again later.');
    }
  };

  return (
      <>
          <div className="contactus"><h1>Contact us</h1></div>
          <div className="contact-outer">
              <div className="contact-form">
                  <form onSubmit={sendEmail} ref={formRef}>
                      <input placeholder='Name' type="text" id="name" name="name"  required />
                      <input placeholder='Email' type="email" id="email" name="email"  required />
                      <input placeholder='Phone Number' type="number" id="phno" name="phno"  required />
                      <textarea placeholder='Message....' id="message" name="message" required />
                      <button type="submit" value="Send">Send</button>
                  </form>
              </div>
        <div className="contact-info">
            <p><FontAwesomeIcon icon={faPhone} /> <span5>Phone</span5> : +91 7200861688</p>
            <p><FontAwesomeIcon icon={faMobile} /> <span5>Mobile</span5> : +91 9790597137</p>
            <p><FontAwesomeIcon icon={faEnvelope} /> <span5>Email</span5> : jaasmedia@gmail.com</p>
            <p><FontAwesomeIcon icon={faLocationDot} /> <span5>Address</span5>:- Jass media (Karaikudi News) 98/8 , Navakala complex , 100 feet road , Karakudi-630003 Sivagangai District</p>
        </div>
        <div className="contact-privacy">
            <p>Privacy policy</p>
            <p>Terms & Conditions</p>
            <p>Refund</p>
        </div>
    </div>
    </>
  )
}

export default Contactus;